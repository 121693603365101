import {
  Alert,
  Box,
  Container,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import { PayWithMercadoPagoButton } from 'components'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { metaPixel } from 'services/meta-pixel'
import TiktokPixel from 'tiktok-pixel'
import { IIngresso } from 'types/ingresso'
import { IPedido } from 'types/pedido'
import { functionWithLoading } from 'utils'
import { moneyFormatter } from 'utils/formatter'

const CreateOrderPage: React.FC = () => {
  const { eventId } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useLocation() as any
  const [loading, setLoading] = useState<boolean>(false)
  const [order, setOrder] = useState<IPedido>()
  const navigate = useNavigate()

  useEffect(() => {
    const bag: IIngresso[] = location.state.bag ?? []
    if (!!bag.length && !order) {
      const ref = localStorage.getItem(eventId + '_ref')
      functionWithLoading(
        apiV1.orderService
          .createOrder({
            eventId: Number(eventId ?? 0),
            tickets: bag,
            ref,
          })
          .then(({ data, status }) => {
            if (status === 201) {
              setOrder(data)
              if (data.evento.facebook_pixel) {
                metaPixel.initPixel(data.evento.facebook_pixel)
                metaPixel.initiateCheckout()
              }
              if (data.evento.google_analytics) {
                ReactGA.initialize(data.evento.google_analytics)
                ReactGA.pageview(
                  window.location.pathname + window.location.search,
                )
              }
              if (data.evento.google_tag_manager) {
                TagManager.initialize({
                  gtmId: data.evento.google_tag_manager,
                })
              }
              if (data.evento.tiktok_pixel) {
                TiktokPixel.init(data.evento.tiktok_pixel)
                TiktokPixel.track('InitiateCheckout', {})
                TiktokPixel.track('PlaceAnOrder', {
                  content_type: 'product',
                  quantity: data.pedido_item?.length,
                  description: data.pedido_item?.reduce(
                    (text: string, item: { ingresso: { titulo: string } }) =>
                      `${text} - ${item.ingresso.titulo} `,
                    '',
                  ),
                  content_id: data.codigo,
                  currency: 'BRL',
                  value: data.valor_pedido,
                })
              }
            } else {
              navigate('/usuario/completar-cadastro', {
                replace: true,
                state: { from: location },
              })
            }
          }),
        setLoading,
      )
    }
  }, [eventId, location, navigate, order])

  if (loading)
    return (
      <Container maxWidth="sm">
        <Box sx={{ width: '100%', my: 2 }}>
          <LinearProgress />
        </Box>
        <Typography variant="h5">Gerando o pedido...</Typography>
      </Container>
    )

  if (!order)
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" gutterBottom>
          Ocorreu um erro ao gerar o pedido, saia e entre em sua conta e realize
          uma nova tentativa por favor. Caso o erro persista, entre em contato
          com o suporte{' '}
          <Link
            sx={{ color: 'blue' }}
            href="https://api.whatsapp.com/send?phone=5517996147824&amp;text=Olá,%20desejo efetuar a troca de titularidade do meu ingresso"
          >
            (17) 99614-7824 via WhatsApp
          </Link>
        </Typography>
      </Container>
    )

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Paper sx={{ p: 2 }}>
        <Alert severity="info">
          Após a finalização da sua compra, por questões de segurança, nossa
          equipe financeira poderá entrar em contato através do número 17
          99614-7824, solicitando o envio de alguns documentos. Mantenha seus
          dados cadastrais atualizados em nosso site. Atenciosamente, Financeiro
          Zé do Ingresso.
        </Alert>
        <Typography variant="h6" gutterBottom>
          Resumo do pedido
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Evento: {order.evento.titulo}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Data: {moment(order.evento.data).add(3, 'hours').format('LLLL')}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Pedido: {order.codigo}
        </Typography>
        <List disablePadding>
          {order.pedido_item?.map((item, index) => (
            <ListItem key={`${item.id}_${index}`} sx={{ py: 1, px: 0 }}>
              <ListItemText
                primary={item.ingresso.titulo}
                secondary={`taxa de serviço`}
              />
              <ListItemText
                primary={moneyFormatter.format(item.valor_ingresso)}
                secondary={moneyFormatter.format(item.valor_taxa)}
                sx={{ textAlign: 'right' }}
              />
            </ListItem>
          ))}
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Total" />
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
              {moneyFormatter.format(
                order.pedido_item?.reduce(
                  (a, b) => Number(a) + Number(b.valor_total),
                  0,
                ) ?? 0,
              )}
            </Typography>
          </ListItem>
        </List>
        <Box display="flex" justifyContent="flex-end">
          {/* {eventId === "872" ? (
            <PayWithPagSeguroButton orderId={order.codigo} />
          ) : ( */}
          <PayWithMercadoPagoButton orderId={order.id} />
          {/* )} */}
        </Box>
      </Paper>
    </Container>
  )
}

export default CreateOrderPage
