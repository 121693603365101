declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbq: (...args: any[]) => void
  }
}

const fbq = window.fbq

// Adicionar informações de pagamento
function addPaymentInfo() {
  fbq('track', 'AddPaymentInfo')
}

// Adicionar ao carrinho
function addToCart() {
  fbq('track', 'AddToCart')
}

// Adicionar à lista de desejos
function addToWishlist() {
  fbq('track', 'AddToWishlist')
}

// Concluir registro
function completeRegistration() {
  fbq('track', 'CompleteRegistration')
}

// Contato
function contact() {
  fbq('track', 'Contact')
}

// Personalizar produto
function customizeProduct() {
  fbq('track', 'CustomizeProduct')
}

// Doar
function donate() {
  fbq('track', 'Donate')
}

// Encontrar localização
function findLocation() {
  fbq('track', 'FindLocation')
}

// Iniciar finalização da compra
function initiateCheckout() {
  fbq('track', 'InitiateCheckout')
}

// Lead
function lead() {
  fbq('track', 'Lead')
}

// Compra
function purchase(value: number, currency: string) {
  fbq('track', 'Purchase', { value, currency })
}

// Programar
function schedule() {
  fbq('track', 'Schedule')
}

// Pesquisa
function search() {
  fbq('track', 'Search')
}

// Iniciar período de avaliação
function startTrial(value: string, currency: string, predictedLtv: string) {
  fbq('track', 'StartTrial', { value, currency, predicted_ltv: predictedLtv })
}

// Enviar solicitação
function submitApplication() {
  fbq('track', 'SubmitApplication')
}

// Assinar
function subscribe(value: string, currency: string, predictedLtv: string) {
  fbq('track', 'Subscribe', { value, currency, predicted_ltv: predictedLtv })
}

// Conteúdo visualizado
function viewContent() {
  fbq('track', 'ViewContent')
}

function initPixel(id: string) {
  fbq('init', id)
}

function pageView() {
  fbq('track', 'PageView')
}

function event(name: string, data: Record<string, unknown>) {
  fbq('track', name, data)
}

export const metaPixel = {
  addPaymentInfo,
  addToCart,
  addToWishlist,
  completeRegistration,
  contact,
  customizeProduct,
  donate,
  findLocation,
  initiateCheckout,
  lead,
  purchase,
  schedule,
  search,
  startTrial,
  submitApplication,
  subscribe,
  viewContent,
  initPixel,
  pageView,
  event,
}
